const brandColors = {
  primary: '#000000',
  secondary: '#fff46b',
};

const sidebarColors = {
  backgroundColor: '#fff46b',
  color: '#000000',
  dotfileLogo: '#000000',
};

const linkColors = {
  color: '#000000',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
